import React, { Fragment, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../context";

import {
  Box,
  Button,
  Container,
  Grid,
  List,
  ListItem,
  ListItemDecorator,
  ListItemButton,
} from "@mui/material";

function Sidebar() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { isAuthenticated, loading, user } = useContext(AuthContext);

  return (
    <>
      {user.role == "admin" && (
         <List>
        <Fragment>
          <ListItem>
              <ListItemButton
                sx={pathname == "/home" && { backgroundColor: "#C0C0C0" }}
                onClick={() => {
                  navigate("/home");
                }}
              >
                Home
              </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={pathname == "/myCompany" && { backgroundColor: "#C0C0C0" }}
              onClick={() => {
                navigate("/myCompany");
              }}
            >
              My Companies
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={pathname === "/createList" && { backgroundColor: "#C0C0C0" }}
              onClick={() => {
                navigate("/createList");
              }}
            >
              Add List
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={pathname === "/createListBulk" && { backgroundColor: "#C0C0C0" }}
              onClick={() => {
                navigate("/createListBulk");
              }}
            >
              Add Bulk List
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={pathname === "/myEmployee" && { backgroundColor: "#C0C0C0" }}
              onClick={() => {
                navigate("/myEmployee");
              }}
            >
              My Employess
            </ListItemButton>
          </ListItem>

          <ListItem>
        <ListItemButton
          sx={pathname === "/listForm" && { backgroundColor: "#C0C0C0" }}
          onClick={() => {
            navigate("/listForm");
          }}
        >
          Recent Uploads
        </ListItemButton>
          </ListItem>

          <div style={{ 
            textAlign:"center",
            // backgroundColor: "#C0C0C0",
                // background: "#15803d",
                // color: "white",
                fontWeight: 800,
                }}>  INSTALLATION </div>
          {/* ################################################################################## */}
          <ListItem>
        <ListItemButton
          sx={pathname === "/installCompanyList" && { backgroundColor: "#C0C0C0" }}
          onClick={() => {
            navigate("/installCompanyList");
          }}
        >
          All Company
        </ListItemButton>
          </ListItem>

          {/* <ListItem>
        <ListItemButton
          sx={pathname === "/allWorks" && { backgroundColor: "#C0C0C0" }}
          onClick={() => {
            navigate("/allWorks");
          }}
        >
          All Works
        </ListItemButton>
          </ListItem> */}

<       ListItem>
        <ListItemButton
          sx={pathname === "/createBulkAssignWork" && { backgroundColor: "#C0C0C0" }}
          onClick={() => {
            navigate("/createBulkAssignWork");
          }}
        >
            Assign Work
        </ListItemButton>
          </ListItem>

          <ListItem>
        <ListItemButton
          sx={pathname === "/installingEmployee" && { backgroundColor: "#C0C0C0" }}
          onClick={() => {
            navigate("/installingEmployee");
          }}
        >
          My Employee 
        </ListItemButton>
          </ListItem>

          <ListItem>
        <ListItemButton
          sx={pathname === "/recentInstall" && { backgroundColor: "#C0C0C0" }}
          onClick={() => {
            navigate("/recentInstall");
          }}
        >
          Recent Uploads
        </ListItemButton>
          </ListItem>
        </Fragment>
        </List>
      )}

        {user.role == "reccee" && (
          <List>
           {/* <ListItem>
              <ListItemButton
                sx={pathname == "/home" && { backgroundColor: "#C0C0C0" }}
                onClick={() => {
                  navigate("/emplist");
                }}
              >
                EMLIST
              </ListItemButton>
          </ListItem> */}

          {/* <ListItem>
              <ListItemButton
                sx={pathname == "/home" && { backgroundColor: "#C0C0C0" }}
                onClick={() => {
                  navigate("/createForm");
                }}
              >
                ADD FORM
              </ListItemButton>
          </ListItem> */}
        </List>
       )}

     
      

    </>
  );
}

export default Sidebar;
