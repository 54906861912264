import { useContext, useState, useEffect, useMemo } from "react";
import axios from "axios";
import { base_url, addList } from "../../api";
import { AuthContext } from "../../context";
import { alertBox } from "../../utils/AlertDailog";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { Grid } from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import Table from "../../components/Table";
import { useFormik } from "formik";
import { bulkAddStoreValidation } from "../../feature/validation";
function CreateBulkAssignWork() {
  const [progress, setProgress] = useState(0);

  const [data, setData] = useState([]);

  const uploadAllData = async (values) => {
    try {
      const { companyId, visitedBy } = values;
      const payloadArr = data.map((item) => {
        return { ...item, companyId: companyId, visitedBy: visitedBy };
      });
      const res = await Promise.all(
        payloadArr.map((payload) => addList(payload))
      );
      if (res) {
        alertBox({
          data: { message: "Data uploaded SuccessFully" },
        });
        setData([]);
      }
    } catch (error) {
      alertBox({ error, data });
    }
  };

  const formik = useFormik({
    validationSchema: bulkAddStoreValidation,
    initialValues: {
      companyId: "",
      visitedBy: "",
    },
    validateOnMount: true,
    onSubmit: (values) => {
      if (data.length === 0) {
        alertBox({
          data: { message: "File is Empty Please choose Another file" },
        });
        return;
      }
      uploadAllData(values);
    },
  });

  const [company, setCompany] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const ViewCompanyFn = async () => {
    try {
      let response = await axios.get(`${base_url}/api/install/company`);
      setCompany(response.data.data);
    } catch (error) {}
  };

  const ViewEmployeeListFn = async () => {
    try {
      let response = await axios.get(`${base_url}/api/install/user`);
      setEmployeeList(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    ViewCompanyFn();
    ViewEmployeeListFn();
  }, []);

  const handleFileChange = async (e) => {
    genateInstallWorkId();
    try {
      const file = e.target.files[0];

      if (!file)
        alertBox({
          data: { message: "Something went wrong or unable to read the file" },
        });
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 3 });

        let currentArr = [];

        let result = jsonData.reduce((res, item) => {
          if (item.hasOwnProperty("s_no")) {
            if (currentArr.length > 0) {
              res.push(currentArr);
            }
            currentArr = [];
          }
          currentArr.push(item);
          return res;
        }, []);
        console.log("res", result);
        let payload = [];
        for (let i = 0; i < result.length; i++) {
          let id = "";
          for (let j = 0; j < result[i].length; j++) {
            if (!id) {
              let payLodForId = {
                s_no: result[i][0]["s_no"],
                shop_name: result[i][0]["shop_name"],
                address: result[i][0]["address"],
                locationLink: result[i][0]["locationLink"],
                companyId: formik.values.companyId,
                assiagnedId: formik.values.visitedBy,
                phone_no: result[i][0]["phone_no"],
              };
              id = await genateInstallWorkId(payLodForId, id);
            }
            payload.push({
              requirment_type: result[i][j].requirment_type,
              width: result[i][j].width,
              height: result[i][j].height,
              quantity: result[i][j].quantity,
              allWorkId: id,
            });
          }
        }

        console.log("pyalod", payload);

        try {
          const res = await await axios.post(
            `${base_url}/api/install/work/Addition/Data`,
            payload
          );
          console.log(res);
          alertBox({
            data: { message: "Work Upload SuccessFully" },
          });
        } catch (error) {
          console.log(error);
          alertBox({
            data: { message: "Something went wrong ..." },
          });
        }
      };
    } catch (error) {
      console.log(error);
      alertBox({
        data: { message: "Something went wrong or unable to read the file" },
      });
    }
  };
  const genateInstallWorkId = async (payLoad) => {
    try {
      const res = await axios.post(`${base_url}/api/install/work`, payLoad);

      return res.data.data._id;
    } catch (error) {
      alert("errro");
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <div className="App my-10 ">
          <form className="md:max-w-2xl md:mx-auto md:flex md:flex-col md:justify-center md:w-auto space-y-2 flex flex-col mx-10 ">
            <label className="font-bold" htmlFor="phone_no">
              Company Name
            </label>
            <select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              required
              name="companyId"
              placeholder="Name"
            >
              <option selected>Select Company</option>
              {company.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.companyId && (
              <p className="text-red-600 text-lg">{formik.errors.companyId}</p>
            )}
            <label className="font-bold" htmlFor="name">
              Visit By
            </label>
            <select
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="visitedBy"
              required
              placeholder="Name"
            >
              <option selected>Select Visit By</option>
              {employeeList.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {formik.errors.visitedBy && (
              <p className="text-red-600 text-lg">{formik.errors.visitedBy}</p>
            )}

            <>
              <input
                className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300  text-cyan-400 bg-clip-padding px-3 py-[0.32rem] font-normal leading-[2.15] text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-cyan-400 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:border-primary focus:text-neutral-700 focus:shadow-te-primary focus:outline-none dark:border-neutral-600 dark:text-neutral-200 dark:file:bg-neutral-700 dark:file:text-neutral-100 dark:focus:border-primary"
                type="file"
                accept=".xlsx"
                disabled={
                  formik.errors.visitedBy || formik.errors.companyId
                    ? true
                    : false
                }
                onChange={handleFileChange}
              />

              {(formik.errors.visitedBy || formik.errors.companyId) && (
                <p className="text-red-600 text-lg">
                  {"Please Chose Company and Assiagnee"}
                </p>
              )}
            </>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default CreateBulkAssignWork;
