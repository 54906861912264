import React, { useContext, useEffect, useMemo, useState } from "react";
import { base_url } from "../../api";
import Table from "../../components/Table";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { AuthContext } from "../../context";
import { saveAs } from "file-saver";
import DeleteIcon from "@mui/icons-material/Delete";
import ExcelJS from "exceljs/dist/exceljs.min.js";
import * as XLSX from "xlsx/xlsx.mjs";
import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import axios from "axios";
import { alertBox } from "../../utils/AlertDailog";
import { PermissionForDelete } from "../../utils/PermissionDialog";
const toDataURL = (url) => {
  const promise = new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        resolve({ base64Url: reader.result });
      };
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  });

  return promise;
};

function InstallCompanyView() {
  const { token } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [comapnyList, setComapnyList] = useState([]);
  const [pending, setPending] = useState(0);
  const [dataForFilter, setDataForFilter] = useState([]);
  const [count, setCount] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [companyName, setCompanyName] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const { installCompanyId } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);

  const deleteEntity = async (allWorkId) => {
    try {
      let currStoreForDelete = dataForFilter.find(
        (_item) => allWorkId === _item._id
      );
      // console.log(currStoreForDelete);
      if (currStoreForDelete) {
        PermissionForDelete().then(async (res) => {
          if (res) {
            await axios.delete(`${base_url}/api/install/work/${allWorkId}`);
            alertBox({ data: { message: "Store Deleted Successfully" } });
            updateTable();
            return;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const ViewCompanyFn = async () => {
    try {
      let response = await axios.get(
        `${base_url}/api/install/company/${installCompanyId}`
      );

      setPending(
        response.data.data.companyList.reduce((prev, item) => {
          if (item.status === "pending") return (prev += 1);
          return prev;
        }, 0)
      );
      setDataForFilter(response.data.data.companyList);

      setData(
        response.data.data.companyList.sort((a, b) => {
          if (a.status < b.status) return 1;
          if (a.status > b.status) return -1;
          return 0;
        })
      );
      setCompanyName(response.data.data.companyName);
    } catch (error) {}
  };

  const handleSelectRow = (rowId) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(rowId)
        ? prevSelectedRows.filter((id) => id !== rowId)
        : [...prevSelectedRows, rowId]
    );
  };

  const updateTable = async (employeList) => {
    ViewCompanyFn();
    try {
      const { data } = await axios.get(
        `${base_url}/api/install/company/${installCompanyId}`
      );
       
       if (data.data.companyList.length === 0) {
          setData([]);
          setComapnyList([]);
        } else {
          // Update the data and companyList state variables
          setData(data.data.companyList);
          setComapnyList(data.data.companyList);
        }
    } catch (error) {}
  };
  const ViewEmployeeListFn = async () => {
    try {
      let response = await axios.get(`${base_url}/api/install/user`);
      // console.log(response);
      setEmployeeList(response.data.data);

      updateTable(response.data.data);
    } catch (error) {}
  };
  useEffect(() => {
    ViewEmployeeListFn();
  }, []);
  useEffect(() => {
    setData(
      dataForFilter.filter(
        (_item) =>
          _item.shop_name
            .toLowerCase()
            .indexOf(searchInput.toLocaleLowerCase()) >= 0 ||
          _item.address
            .toLowerCase()
            .indexOf(searchInput.toLocaleLowerCase()) >= 0
      )
    );
  }, [searchInput]);

  const changeAssign = async (index, AssignId) => {
    const workId = data[index]._id;
    const currentAssignedEmployee = employeeList.find(
      (emp) => emp._id === AssignId
    );

    try {
      let response = await axios.put(
        `${base_url}/api/install/work/employee/${workId}`,
        {
          assiagnedId: AssignId,
        }
      );
      // console.log("before data", data);
      let allData = [...data];
      // console.log("allDate before ", allData);

      allData[index].assiagnedId = {
        _id: AssignId,
        name: currentAssignedEmployee.name,
      };
      console.log("Afleter chagne AllDateaw", allData);
      setData(allData);
    } catch (error) {}
  };

  const deleteAllRows = async ( ) => {
   
    try {
      // alert(selectedRows)
      const promises = selectedRows.map((storeId) =>
        axios.delete(`${base_url}/api/install/work/${storeId}`)
      );
  
      // Wait for all promises to resolve
      await Promise.all(promises);
        setSelectedRows([]);
      // Refresh the data after updating
      alertBox({ data: { message: "Records is deleted." } });
      updateTable();
    } catch (error) {
      console.error(error);
    }

  };

    // select employee change all working 
const ResignMultipleEntities = async (assiagnedId) => {
  try {

    const promises = selectedRows.map((storeId) =>
      axios.put(`${base_url}/api/install/work/employee/${storeId}`, {
        assiagnedId,
      })
    );

    // Wait for all promises to resolve
    await Promise.all(promises);
    document.querySelector('select[name="companyId"]').selectedIndex = 0;
    selectedRows.length = 0;
    // Refresh the data after updating
    updateTable();
    alertBox({ data: { message: "Employee Data is updated." } });
  } catch (error) {
    console.error(error);
  }
};

  const columns = [
    {
      // Multi-Select Checkbox Column
      Header: (
        <input
          type="checkbox"
          checked={
            selectedRows.length === data.length && selectedRows.length > 0
          } // Check if all rows are selected
          onChange={() =>
            setSelectedRows(
              selectedRows.length === data.length
                ? []
                : data.map((item) => item._id)
            )
          }
        />
      ),
      accessor: "_id",
      callback: (id) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(id)}
          onChange={() => handleSelectRow(id)}
        />
      ),
    }, 
    {
      Header: "S No.",
      accessor: "s_no"
    }, 
    {
      Header: "Store Name",
      accessor: "shop_name",
    },
    {
      Header: "Area",
      accessor: "address",
    },
    {
      Header: "Assigned to",
      accessor: "assiagnedId.name",
    },
    {
      Header: "Re-Assign",
      accessor: "assiagnedId.name",
      callback: (value, idx) => {
        return data[idx].status === "pending" ? (
          <div>
            <select
              onChange={(event) => changeAssign(idx, event.target.value)}
              required
              defaultValue={value}
              name="companyId"
              placeholder="Name"
            >
              <option defaultValue={value} selected>
                Select Employee
              </option>
              {employeeList.map((item, value) => {
                return (
                  <option value={item._id} key={value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
        ) : (
          "Completed"
        );
      },
    },
    {
      Header: "Download",
      accessor: "_id",
      callback: (id) => {
        return (
          <button type="submit" onClick={() => exportExcelFile2(id)}>
            <ImportExportIcon />
          </button>
        );
      },
    },
    {
      Header: "Delete ",
      accessor: "_id",
      callback: (id, status) => {
        return (
          <button
            type="submit"
            onClick={() => {
              deleteEntity(id);
            }}
          >
            <DeleteIcon />
          </button>
        );
      },
    },

    {
      Header: "Stauts",
      accessor: "status",
    },
  ];

  const exportExcelFile2 = async (id) => {
    let { data: successData } = await axios.get(
      `${base_url}/api/install/admin/work/image/${id}`
    );

    const data = successData.data.additioanlModel.map((item) => {
      item.shop_name = item.allWorkId.shop_name;
      item.address = item.allWorkId.address;
      // item.s_no = item.allWorkId.s_no;
      delete item.__v;
      delete item.s_no;
      delete item._id;
      delete item.allWorkId;
      delete item.status;
      delete item.createdAt;
      delete item.updatedAt;
      return item;
    });
    // console.log({ data });
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");

    sheet.columns = [
      // {
      //   key: "s_no",
      //   width: 5,
      // },
      {
        // header: "Shop Name",
        key: "shop_name",
        width: 10,
      },
      {
        // header: "Address",
        key: "address",
        width: 32,
      },
      {
        // header: "Type",
        key: "requirment_type",
        width: 20,
      },
      {
        // header: "Width",
        key: "width",
        width: 20,
      },
      {
        // header: "Height",
        key: "height",
        width: 15,
      },
      {
        // header: "Quantity",
        key: "quantity",
        width: 10,
      },
    ];
    const idx = { index: 0 };
    const promise = Promise.all(
      data?.map(async (product, index) => {
        let rowNumber = index * 2 + 0; // Adjusted row number to leave space for empty rows
        sheet.addRow({
          // s_no: product?.s_no,
          shop_name: product?.shop_name,
          address: product?.address,
          requirment_type: product?.requirment_type,
          width: product?.width,
          height: product?.height,
          quantity: product?.quantity,
        });
        sheet.addRow({}).height = 335;

        // console.log(product?.thumbnail);

        const getImageDimensions = (imageUrl) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              resolve({ width, height });
            };
            img.onerror = (error) => {
              reject(error);
            };
            img.src = imageUrl;
          });
        };
        

        const image = product?.imageUrl;
        const dimensions = await getImageDimensions(image);
        const imageWidth = dimensions.width;
        const imageHeight = dimensions.height;
   
        const result = await toDataURL(image);
        const splitted = image.split(".");
        const extName = splitted[splitted.length - 1];

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        });
        sheet.mergeCells(`A${rowNumber}:H${rowNumber}`);
        // sheet.mergeCells(`A${rowNumber}:G${rowNumber}`);
        idx.index = rowNumber;
        sheet.addImage(imageId2, {
          tl: { col: 1, row: rowNumber + 1 },
          ext: { width: imageWidth, height: imageHeight },
        });
      })
    );

    promise.then(() => {
      const priceCol = sheet.getColumn(6);

      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      sheet.eachRow((row) => {
        row.eachCell((cell) => {
          // Add border to each cell in the row
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };

           // Center the image in the cell
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };

        });
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "download.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  const exportExcelFileAll = async () => {
    let { data: successData } = await axios.get(
      `${base_url}/api/install/admin/work/image/export/all/${installCompanyId}`
    );

    const data = successData.data.additioanlModel.map((item) => {
      item.shop_name = item.allWorkId.shop_name;
      item.address = item.allWorkId.address;
      item.s_no = item.allWorkId.s_no;
      delete item.__v;
      delete item._id;
      delete item.allWorkId;
      delete item.status;
      delete item.createdAt;
      delete item.updatedAt;
      return item;
    });
    // console.log({ data });
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");

    sheet.columns = [
      {
        key: "s_no",
        width: 5,
      },
      {
        // header: "Shop Name",
        key: "shop_name",
        width: 10,
      },
      {
        // header: "Address",
        key: "address",
        width: 32,
      },
      {
        // header: "Type",
        key: "requirment_type",
        width: 20,
      },
      {
        // header: "Width",
        key: "width",
        width: 20,
      },
      {
        // header: "Height",
        key: "height",
        width: 15,
      },
      {
        // header: "Quantity",
        key: "quantity",
        width: 10,
      },
    ];
    const idx = { index: 0 };
    const promise = Promise.all(
      data?.map(async (product, index) => {
        let rowNumber = index * 2 + 0; // Adjusted row number to leave space for empty rows
        sheet.addRow({
          s_no: product?.s_no, // Use index + 1 as the serial number
          shop_name: product?.shop_name,
          address: product?.address,
          requirment_type: product?.requirment_type,
          width: product?.width,
          height: product?.height,
          quantity: product?.quantity,
        });
        sheet.addRow({}).height = 335;

        // console.log(product?.thumbnail);

        const getImageDimensions = (imageUrl) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
              const width = img.width;
              const height = img.height;
              resolve({ width, height });
            };
            img.onerror = (error) => {
              reject(error);
            };
            img.src = imageUrl;
          });
        };
        

        const image = product?.imageUrl;
        const dimensions = await getImageDimensions(image);
        const imageWidth = dimensions.width;
        const imageHeight = dimensions.height;
   
        const result = await toDataURL(image);
        const splitted = image.split(".");
        const extName = splitted[splitted.length - 1];

        const imageId2 = workbook.addImage({
          base64: result.base64Url,
          extension: extName,
        });
        sheet.mergeCells(`A${rowNumber}:G${rowNumber}`);
        idx.index = rowNumber;
        sheet.addImage(imageId2, {
          tl: { col: 1, row: rowNumber + 1 },
          ext: { width: imageWidth, height: imageHeight },
        });
      })
    );

    promise.then(() => {
      const priceCol = sheet.getColumn(6);

      priceCol.eachCell((cell) => {
        const cellValue = sheet.getCell(cell?.address).value;
        // add a condition to set styling
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      sheet.eachRow((row) => {
        row.eachCell((cell) => {
          // Add border to each cell in the row
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };

           // Center the image in the cell
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };

        });
      });

      workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "download.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
    });
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        {/* {Model} */}
        <Grid item xs={10}>
          <h1
            style={{
              textAlign: "center",
              color: "#283747",
              fontSize: "28px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {companyName}
          </h1>
          <p
            style={{
              textAlign: "center",

              color: "red",
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            <button
              className="bg-neutral-700 text-white btnCommon  h-10 w-28 text-xs rounded-xl"
              onClick={() => exportExcelFileAll()}
            >
              {" "}
              EXPORT ALL
            </button>
          </p>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "space-around",
            }}
          >        
            <div className="mr-5">
            <button onClick={ deleteAllRows }>Delete all </button>
            <br/>
              <span
                style={{
                  textAlign: "center",
                  color: "#283747",
                  fontWeight: "bold",
                  marginBottom: "20px",
                }}
              >
                Pending : {pending}{" "}
              </span>
              <label
                for="default-search"
                class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div class="relative">
                <div class="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="search"
                  id="default-search"
                  class="block h-10 w-54 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Store"
                />
              </div>

               {/* select employee change all working  */}
               <div style={{marginTop : 20}}>
            <select
              // onChange={}
              onChange={(e) => ResignMultipleEntities(e.target.value)}
              required
              // defaultValue={value}
              name="companyId"
              placeholder="Name"
            >
              <option value="" selected>
                Select Employee
              </option>

              {employeeList.map((item, value) => {
                return (
                  <option value={item._id} key={value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "row", xs: "column" },
                alignItems: "center",
                justifyContent: { xs: "center", sm: "flex-end" },
                marginBottom: "10px",
                width: "100%",
              }}
            ></Box>

            {data && (
              <Table
                columns={columns}
                datas={data}
                updateTable={updateTable}
                count={count}
              />
            )}
          </Container>
        </Grid>
      </Grid>
    </div>
  );
}

export default InstallCompanyView;
