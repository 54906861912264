import { useContext, useState, useEffect } from "react";
import FileInput from "../components/createForm/FileInput";
import axios from "axios";
import { saveForm, base_url } from "../api";
import { AuthContext } from "../context";
import { alertBox } from "../utils/AlertDailog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { Box, Button, Container, Grid } from "@mui/material";

function CreateForm() {
  const { state } = useLocation();
  const navigate = useNavigate();
  console.log("_____==", state);
  const { addListId } = useParams();
  const { token, user } = useContext(AuthContext);
  const [shop_name, set_shop_name] = useState(state ? state.shop_name : "");
  const [name, set_name] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [address, set_address] = useState(state ? state.address : "");
  const [phone_no, set_phone_no] = useState(
    state ? (state.state ? state?.assiagnedId?.mobile_no : "") : ""
  );
  const [gst, set_gst] = useState("");
  const [status, setStatus] = useState("");
  const [vistingCard, setVisitingCard] = useState({});
  const [shopImage, setShopImageCard] = useState({});
  const [location, setCurrentLocation] = useState(
    state
      ? state.locationLink
      : "https://www.google.com/maps/search/?api=1&query="
  );
  const [listId, set_addListId] = useState("");
  const [addListName, setAddListName] = useState({});
  const [selectedFiles, setSelectedFiles] = useState([
    state
      ? {
          tempFile: null,
          file: null,
          height: state.additional[0].height,
          width: state.additional[0].width,
          quantity: "",
          remarks: state.additional[0].remarks,
          requirment_type: state.additional[0].type,
        }
      : {
          tempFile: null,
          file: null,
          height: "",
          width: "",
          quantity: 1,
          remarks:"",
          requirment_type: "",
        },
  ]);

  const handleSubmit = async (event) => {
    setisLoading(true);
    event.preventDefault();
    const { error, data } = await saveForm({
      selectedFiles,
      shop_name,
      address,
      phone_no,
      status,
      gst,
      location,
      addListId: addListName._id,
      name: addListName.visitedBy.name,
      token,
      vistingCard,
      shopImage
    });
    setisLoading(false);

    if (error?.message) {
      alertBox({ error, data });
    } else {
      alertBox({ error, data }).then((e) => {
        if (user?.role !== "admin") {
          // window.location.reload();
          navigate("/emplist");
        } else navigate("/listForm");
      });
    }
  };

  const ViewCompanyFn = async () => {
    try {
      let response = await axios.get(`${base_url}/api/company`);
    } catch (error) {}
  };

  const AddDetailsFn = async () => {
    try {
      let response = await axios.get(
        `${base_url}/api/company/empList/addList/${addListId}`
      );
      setAddListName(response.data.data);
    } catch (error) {}
  };

  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back ?")) {
        setfinishStatus(true);
        navigate("/emplist");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setCurrentLocation(
        `https://www.google.com/maps/search/?api=1&query=${position.coords.latitude},${position.coords.longitude}`
      );
    });

    ViewCompanyFn();
    AddDetailsFn();
  }, []);
  const [previewImage, setPreviewImage] = useState(null);
  const [previewShopImage, setShopPreviewImage] = useState(null);
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setVisitingCard(file);
  };

  const handleShopImageFileChange = async (event) => {
    const file = event.target.files[0];
    setShopImageCard(file);
  };

  const handleInputChange = (ev) => {
    const inputValue = ev.target.value;
  
    // Remove any non-digit characters from the input
    const numericInput = inputValue.replace(/\D/g, '');
  
    // Limit the input to exactly 10 digits
    if (numericInput.length <= 10) {
      set_phone_no(numericInput);
    }
  };

  const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={10}>
        <div className="App my-10 ">
          <form
            onSubmit={handleSubmit}
            className="md:max-w-2xl md:mx-auto md:flex md:flex-col md:justify-center md:w-auto space-y-2 flex flex-col mx-10 "
          >
            <label htmlFor="phone_no">
              <span className="font-bold">Store:</span> {addListName.shop_name}
            </label>

            <label className="font-bold" htmlFor="shop_name">
              Shop Name
            </label>
            <input
              type="text"
              id="shop_name"
              onChange={(ev) => set_shop_name(ev.target.value)}
              value={shop_name}
              required
            />

            <label className="font-bold" htmlFor="address">
              Address
            </label>
            <input
              type="text"
              id="address"
              onChange={(ev) => set_address(ev.target.value)}
              value={address}
              required
            />

            <label className="font-bold" htmlFor="phone_no">
              Phone No
            </label>
            <input
              type="tel"
              pattern="[0-9]{10}"
              id="phone_no"
              onChange={(ev) => handleInputChange(ev)}
              value={phone_no}
              required
            />

            <label className="font-bold" htmlFor="gst">
              GST
            </label>
            <input
              type="text"
              id="gst"
              onChange={(ev) => set_gst(ev.target.value)}
              value={gst}
              required
            />

            <label className="font-bold" htmlFor="phone_no">
              Status
            </label>
            <select
              required
              defaultChecked="Completed"
              placeholder="Name"
              onChange={(ev) => setStatus(ev.target.value)}
              value={status}
            >
              <option value="">Select Status</option>
              <option value={"completed"}>Completed</option>
              <option value={"revisted"}>Revisted</option>
              <option value={"cancelled"}>Cancelled</option>
            </select>
            <label className="font-bold" htmlFor="visting_card">
              Visting card
            </label>
            <input type="file" onChange={handleFileChange} required></input>
            {previewImage && (
              <div>
                <h2>Visiting Card:</h2>
                <img
                  src={previewImage}
                  alt="Visiting Card Preview"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            )}

          <h2 style={{fontWeight:700}}>Shop Photo:</h2>
          <input type="file" onChange={handleShopImageFileChange} required></input>
            {previewShopImage && (
              <div>
                <img
                  src={previewShopImage}
                  alt="Shop Image Preview"
                  style={{ maxWidth: "100%" }}
                />
              </div>
            )}

            <FileInput
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              isLoading={isLoading}
            />
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default CreateForm;
