import React from "react";
import "./FileModalInput.css";

function FileModalInput({ index, selectedFiles, setSelectedFiles }) {
  const handleChange = (ev) => {
    const { id, value } = ev.target;
    selectedFiles[id] = value;
    setSelectedFiles((prev) => {
      return [...prev];
    });
  };

  const typeRequirement = [
    "Back Lit Board",
    "Non Lit Board",
    "BLDS Board",
    "Foam + Vinyl",
    "NLDS Board",
    "One Way Vision",
    "Only Vinyl",
    "BLDS Standee",
    "NLDS Standee",
    "Display Board",
    "Foam Display Board",
    "Switch Display Board",
    "Lighting Display Board",
    "Fan Display Board",
    "Fan Display Standee",
    "Lighting Acrylic Logo",
    "Non Lit Acrylic Logo",
    "ACP Board",
    "ACP Panelling",
    "Box Non Lit",
    "Box NLDS",
    "Clear Vinyl",
    "Cutting Vinyl",
    "BL Fabric Board",
    "BLDS Fabric Board",
    "Aluminium Fabric Board",
    "BL Flex Change",
    "BLDS Flex Change",
    "Nl Flex Change",
    "NLDS Flex Change",
    "Acp Board Repair",
    "Back Lit Board Repair",
    "Others",
  ];
  return (
    <>
      <label className="font-bold" htmlFor="width">
        Width
      </label>
      <input
        type="number"
        id="width"
        onChange={handleChange}
        value={selectedFiles?.width}
        required
      />

      <label className="font-bold" htmlFor="height">
        Height
      </label>
      <input
        type="number"
        id="height"
        onChange={handleChange}
        value={selectedFiles?.height}
        required
      />

      {/* <label className="font-bold" htmlFor="quantity">
        Quantity
      </label> */}
      <input
        type="hidden"
        id="quantity"
        onChange={handleChange}
        value={selectedFiles?.quantity}
        required
      />
        <label className="font-bold" htmlFor="remarks">
        Remarks
      </label>
      <input
        type="text"
        id="remarks"
        onChange={handleChange}
        value={selectedFiles?.remarks}
      />

      <label className="font-bold" htmlFor="requirment_type">
        Type of Requirement
      </label>
      <select
        required
        defaultChecked="option1"
        placeholder="Name"
        id="requirment_type"
        onChange={handleChange}
        value={selectedFiles?.requirment_type}
      >
        <option value="">Select Requirement</option>
        {typeRequirement.map((item, index) => (
          <option value={item} key={index}>
            {item}
          </option>
        ))}
      </select>
    </>
  );
}

export default FileModalInput;
