import React, { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../context";
import { Box, Button, Container, Grid } from "@mui/material";

import Sidebar from "../components/Sidebar";
import welcomeImage from "../assests/images/welcome2.jpg";
function Home() {
  const { token } = useContext(AuthContext);

  useEffect(() => {}, [token]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Container
          sx={{
            display: "flex",
          }}
        >
          <img
            style={{ flex: 1 }}
            loading="eager"
            decoding="async"
            src={welcomeImage}
            alt="error"
          />
          {/* <Grid container spacing={2}>
          
            <Grid
              style={{
                width: "200px",
                height: "200px",
                backgroundColor: "darkturquoise",
                borderRadius: "20px",
                textAlign: "center",
                fontSize: "85px",
                color: "white",
                fontWeight: "700",
              }}
              item
              xs={12}
            >
              Welcome To Home Page
            </Grid>
          </Grid> */}
        </Container>
      </Grid>
    </Grid>
  );
}

export default Home;
