import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Loader from "./components/Loader";
import { AuthContext } from "./context";
import CreateForm from "./pages/CreateForm";
import CreateUser from "./pages/CreateUser";
import Home from "./pages/Home";
import ListForm from "./pages/ListForm";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Signup from "./pages/Signup";
import CompanyList from "./pages/CompanyList";
import CreateCompany from "./pages/CreateCompany";
import MyEmployee from "./pages/MyEmployee";
import CompanyView from "./pages/CompanyView";
import EmployeeView from "./pages/EmployeeView";
import CreateAdminForm from "./pages/CreateAdminForm";
import EmployeePage from "./pages/EmployeePage";
import ShopList from "./pages/ShopList";
import EmployeeUserCompany from "./pages/EmployeeUserCompany";
import CreateBulkForm from "./pages/CreateBulkForm";
import InstallingEmployee from "./pages/second-part/InstallingEmployee";
import AllWorks from "./pages/second-part/AllWorks";
import CreateBulkAssignWork from "./pages/second-part/CreateBulkAssignWork";
import RecentInstall from "./pages/second-part/RecentInstall";
import InstallEmployee from "./pages/second-part/employee/InstallEmployee";
import InstallCompanyList from "./pages/second-part/InstallCompanyList";
import InstallCreateCompany from "./pages/second-part/InstallCreateCompany";
import InstallCreateForm from "./pages/second-part/employee/InstallCreateForm";
import InstallCompanyView from "./pages/second-part/InstallCompanyView";
import InstallCompanyEmployeeList from "./pages/second-part/employee/InstallCompanyEmployeeList";
import InstallEmployeeView from "./pages/second-part/InstallEmployeeView";
import InstallEmployeeUserCompany from "./pages/second-part/InstallEmployeeUserCompany";

function Protected({ children, isAuthenticated }) {
  if (!isAuthenticated) return <Navigate to="/" replace />;
  return children;
}

const routes = {
  admin: [
    {
      path: "/home",
      element: <Home />,
    },
    {
      path: "/myCompany",
      element: <CompanyList />,
    },
    {
      path: "/createList",
      element: <CreateAdminForm />,
    },
    {
      path: "/companyView/:companyId",
      element: <CompanyView />,
    },
    {
      path: "/employeeView/:companyId",
      element: <EmployeeView />,
    },
    {
      path: "/myEmployee",
      element: <MyEmployee />,
    },
    {
      path: "/listForm",
      element: <ListForm />,
    },
    {
      path: "/createForm",
      element: <CreateForm />,
    },
    {
      path: "/createUser",
      element: <CreateUser />,
    },
    {
      path: "/createCompany",
      element: <CreateCompany />,
    },
    ,
    {
      path: "/employee/user/company/:companyId/:userId",
      element: <EmployeeUserCompany />,
    },
    {
      path: "/createListBulk",
      element: <CreateBulkForm />,
    },
    {
      path: "/installingEmployee",
      element: <InstallingEmployee />,
    },
    {
      path: "/allWorks",
      element: <AllWorks />,
    },
    {
      path: "/createBulkAssignWork",
      element: <CreateBulkAssignWork />,
    },
    {
      path: "/recentInstall",
      element: <RecentInstall />,
    },
    {
      path: "/installCreateCompany",
      element: <InstallCreateCompany />,
    },
    {
      path: "/installCompanyList",
      element: <InstallCompanyList />,
    },
    {
      path: "/installCompanyView/:installCompanyId",
      element: <InstallCompanyView />,
    },
    {
      path: "/installEmployeeView/:userId",
      element: <InstallEmployeeView />,
    },
    {
      path: "/installEmployeeUserCompany/user/company/:companyId/:userId",
      element: <InstallEmployeeUserCompany />,
    },
  ],
  reccee: [
    {
      path: "emplist",
      element: <EmployeePage />,
    },
    {
      path: "/createForm/:addListId",
      element: <CreateForm />,
    },
    {
      path: "/createCompany",
      element: <CreateCompany />,
    },
    {
      path: "/ShopList/:shopId",
      element: <ShopList />,
    },
  ],
  installing: [
    {
      path: "installCompanyEmployeeList",
      element: <InstallCompanyEmployeeList />,
    },
    {
      path: "/installEmployee/:companyId",
      element: <InstallEmployee />,
    },
    {
      path: "/createForm/:addListId",
      element: <CreateForm />,
    },
    {
      path: "/installCreateForm/:allWorkId",
      element: <InstallCreateForm />,
    },
  ],
};

function App() {
  const { isAuthenticated, loading, user } = useContext(AuthContext);

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader />
      </div>
    );

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {user?.role &&
          routes[user?.role].map((e, i) => (
            <Route
              key={i}
              path={e.path}
              element={
                <Protected isAuthenticated={isAuthenticated}>
                  {e.element}
                </Protected>
              }
            />
          ))}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
