import React, { useContext, useEffect, useMemo, useState } from "react";
import { base_url, getAllForms } from "../api";
import Table from "../components/Table";
import { AuthContext } from "../context";
import moment from "moment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import * as XLSX from "xlsx/xlsx.mjs";
// import Loader from "../components/Loader";
import { Box, Button, Container, Grid, Modal, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import axios from "axios";
import { AddModal } from "../Modal/AddModal";
import { alertBox } from "../utils/AlertDailog";
import { PermissionForDelete } from "../utils/PermissionDialog";

function ListForm() {
  const { token, user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = useState("");

  const [company, setCompanyName] = useState("");
  const [data, setData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [count, setCount] = useState(0);
  const handleOpen = () => setOpen(true);

  const updateTable = (page) => {
    getAllForms({
      token: token,
      skip: page * 10,
      limit: 10,
    }).then(({ error, data }) => {
      setData(data?.data.length > 0 ? data.data : []);
      setCount(data.count);
    });
  };

  const handleClose = () => {
    setImage("");
    setOpen(false);
  };
  useEffect(() => {
    if (token) updateTable(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  const exportToXcel = async (id) => {
    try {
      const { data } = await axios.get(
        `${base_url}/api/company/download-xcel/${id}`
      );
      // debugger;
      if (data.length !== 0 && data?.data[0]) {
        
        const currData = data.data[0];
        const columnHeader = [
          "name",
          "shop_name",
          "address",
          "gst",
          "phone_no",
          "status",
          "location",
          "height",
          "width",
          "quantity",
          "remarks",
          "requirementType",
        ];
        const { formDetails: deatails } = currData;
        console.log(deatails);
        delete currData.formDetails;
        let newData = [
          [
            ...Object.keys(currData).map((key) => currData[key]),
            ...Object.values(deatails[0]),
          ],
          ...deatails.map((item) => [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            ...Object.values(item),
          ]),
        ];

        newData.unshift(columnHeader);
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(newData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const deleteEntity = async (id) => {
    try {
      PermissionForDelete().then(async (res) => {
        if (res) {
          await axios.delete(`${base_url}/api/company/form/${id}`);
          setData((prev) => prev.filter((_item) => id !== _item._id));
          alertBox({ data: { message: "File Deleted Successfully" } });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "updatedAt",
        callback: (value) => {
          return moment(value).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Time",
        accessor: "updatedAt",
        callback: (value) => {
          return moment(value).format("hh:mm A");
        },
      },
      {
        Header: "Company Name",
        accessor: "addListId.companyId.name",
      },
      {
        Header: "Shop Name",
        accessor: "shop_name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created By",
        accessor: "user.createdBy",
      },
      {
        Header: "Download",
        accessor: "_id",
        callback: (id) => {
          return (
            <form method="post" action={`/api/user/download-ppt/${id}`}>
              <input type="hidden" value={token} name="authorization" />
              <button type="submit">
                <CloudDownloadIcon />
              </button>
            </form>
          );
        },
        // onClick: (id) => {
        //   getFormPpt({ id, token });
        // },
      },
      {
        Header: "Export",
        accessor: "_id",
        callback: (id) => {
          return (
            <button type="submit" onClick={() => exportToXcel(id)}>
              <ImportExportIcon />
            </button>
          );
        },
        // onClick: (id) => {
        //   getFormPpt({ id, token });
        // },
      },
      {
        Header: "Delete",
        accessor: "_id",
        callback: (id) => {
          return (
            <button
              type="submit"
              onClick={() => {
                deleteEntity(id);
              }}
            >
              <DeleteIcon />
            </button>
          );
        },
      },
    ],
    [token]
  );

  // if (isLoading)
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <Loader />
  //     </div>
  //   );

  const createCompany = async () => {
    try {
      let payload = {
        name: company,
        logoUrl: imageUrl,
        total: 0,
        completed: 0,
        pending: 0,
        cancelled: 0,
        revisit: 0,
      };
      const res = await axios.post(`${base_url}/company`, payload);

      setData((prev) => [...prev, payload]);
      alert("AlertComapef");

      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "row", xs: "column" },
              alignItems: "center",
              justifyContent: { xs: "center", sm: "flex-end" },
              marginBottom: "10px",
              width: "100%",
            }}
          >
            {/* <Button
          onClick={() => {
            navigate("/createForm");
          }}
          sx={{
            alignItems: "left",
            backgroundColor: "#15803d",
            color: "white",
            my: 1,
            paddingX: "15px",
            ":hover": {
              backgroundColor: "#15803d",
              color: "white",
            },
          }}
        >
          Create PPT
        </Button> */}
          </Box>
          <Table
            columns={columns}
            datas={data}
            updateTable={updateTable}
            count={count}
          />
          <AddModal open={open} setOpen={setOpen} />
        </Container>
      </Grid>
    </Grid>
  );
}

export default ListForm;
