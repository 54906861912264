import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { base_url } from "../../../api";
import { AuthContext } from "../../../context";
import { alertBox } from "../../../utils/AlertDailog";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Box, Button, Container, Grid } from "@mui/material";

function InstallEmployee() {
  const navigate = useNavigate();

  const [seachInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const [dataForSearch, setDataForSearch] = useState([]);
  const { companyId } = useParams();
  const ViewInstallAllWorkFn = async () => {
    try {
      let user = localStorage.getItem("user");
      let userObj = JSON.parse(user);
      let userId = userObj.userId;
      let response = await axios.get(
        `${base_url}/api/install/employee/work/${userId}/${companyId}`
      );
      setData(response.data.data);
      setDataForSearch(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ViewInstallAllWorkFn();
  }, []);
  
  useEffect(() => {
    setData(
      dataForSearch.filter(
        (_item) =>
          _item.shop_name.toLowerCase().indexOf(seachInput.toLocaleLowerCase()) >= 0 ||
          _item.address.toLowerCase().indexOf(seachInput.toLocaleLowerCase()) >= 0
      )
    );
  }, [seachInput]);

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        item
        xs={10}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >

            <div className="mr-5 mb-5">
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  value={seachInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  type="search"
                  id="default-search"
                  className="block h-10 w-54 p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search Work"
                />
              </div>
            </div>

        <div className="w-full mb-5 max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700">
          <h5 className="mb-3 text-base text-center font-semibold text-gray-900 md:text-xl dark:text-white">
            Assigned Work
          </h5>

          <ul className="my-4 space-y-3">
            {data.map((item, index) => {
              return (
                <li
                  className="w-full mb-5 max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700"
                  value={item._id}
                  key={index}
                >
                <Link
                  href="#"
                  className="flex flex-col p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                >
                <span>
                  <strong>Shop Name:</strong> {item.shop_name}
                </span>
                <br/>
                <span>
                  <strong>Address:</strong> {item.address}
                </span>
               </Link>
                  <a
                    href={"tel:" + item?.phone_no}
                    className="flex items-center justify-between"
                  >
                    <a
                      href={"tel:" + item.phone_no}
                      className="flex cursor-pointer items-center p-3 text-base font-bold mt-4 text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                    >
                      <span className="w-16 text-sm whitespace-nowrap text-center">
                        Call
                      </span>
                    </a>
                    <div
                      onClick={() =>
                        window.open(
                          item?.locationLink,
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                      className="flex cursor-pointer items-center p-3 text-base font-bold mt-4 text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                    >
                      <span className="w-16 text-sm whitespace-nowrap text-center  ">
                        Direction
                      </span>
                    </div>

                    <Link
                      className="flex cursor-pointer items-center p-3 text-base font-bold mt-4 text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white"
                      to={`/installCreateForm/${item._id}`}
                    >
                      <span className="w-16 text-sm whitespace-nowrap text-center">
                        {" "}
                        Submit
                      </span>
                    </Link>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </Grid>
    </Grid>
  );
}

export default InstallEmployee;
