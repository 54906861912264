import { useContext, useState, useEffect } from "react";
import FileInput from "../../../components/createForm/FileInput";
import axios from "axios";
import { saveForm, base_url } from "../../../api";
import { AuthContext } from "../../../context";
import { alertBox } from "../../../utils/AlertDailog";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../components/Sidebar";
import { Box, Button, Container, Grid } from "@mui/material";
import Loader from "../../../components/Loader";
import { Permission, PermissionForDelete } from "../../../utils/PermissionDialog";

function InstallCreateForm() {
  const { allWorkId } = useParams();
  console.log(allWorkId);
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);

  const [workDetail, setWorkDetail] = useState({});
  const [additionWorkDetail, setAdditionWorkDetail] = useState([]);
  const [shopName, setShopName] = useState("");
  const [shopAddress, setShopAddress] = useState(""); 

  const allWorkDetailsFn = async () => {
    try {
      let response = await axios.get(
        `${base_url}/api/install/work/image/${allWorkId}`
      );
      setWorkDetail(response.data.data);

      setAdditionWorkDetail(response.data.data);
      setShopName(response.data.data[0].allWorkId.shop_name)
      setShopAddress(response.data.data[0].allWorkId.address); 
    } catch (error) {}
  };

  useEffect(() => {
    allWorkDetailsFn();
  }, []);
  const imageHandler = (event, index) => {
    let task = [...additionWorkDetail];
    const formData = new FormData();
    formData.append("uploadedFile", event.target.files[0]);
    task[index].imageUrl = URL.createObjectURL(event.target.files[0]);
    task[index].imgFile = formData;
    setAdditionWorkDetail(task);
  };
  const uploadImage = async () => {
    try {
      setisLoading(true); // Show the loader
      const allImagePayload = additionWorkDetail
        .map((item) => {
          if (item?.imgFile) {
            return axios.post(
              `${base_url}/api/install/work/image/${item._id}`,
              item?.imgFile
            );
          } else return false;
        })
        .filter(Boolean);
        // await new Promise((resolve) => setTimeout(resolve, 1 * 60 * 1000)); // Delay for 1 minutes
      const res = await Promise.all(allImagePayload);

      // After uploading images, send shop name and address to Telegram
      await axios.post(
        `${base_url}/api/install/sendTelegramMessage`,
        {
          shopName: shopName,
          shopAddress: shopAddress
        }
      );

      console.log(res);
      alertBox({
        data: { message: "Saved Successfully" },
      });
      navigate("/installCompanyEmployeeList");
    } catch (error) {
      console.log(error);
      alertBox({
        data: { message: "Something went wrong ..." },
      });
    }
  };
  const getImageUrl = (fileUrl) => {
    if (typeof fileUrl === "object") {
      return URL.createObjectURL(fileUrl?.get("uploadedFile"));
    } else return `${base_url}/images/${fileUrl}`;
  };

  const deleteFn = async (additionalId) => {
    try {
      PermissionForDelete().then(async (res) => {
        if (res) {
          let response = await axios.delete(
            `${base_url}/api/install/work/image/${additionalId}`
          );
          alertBox({ data: { message: "Image Deleted Successfully" } });
          allWorkDetailsFn();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
      <Grid item xs={10}>
        <div className="App my-10 ">
          <div
            // onSubmit={handleSubmit}
            className="md:max-w-2xl md:mx-auto md:flex md:flex-col md:justify-center md:w-auto space-y-2 flex flex-col mx-10 "
          >
            <label className="font-bold" htmlFor="phone_no">
              {shopName}
            </label>

            {additionWorkDetail.map((item, index) => {
              return (
                <div
                  key={index}
                  className="border-4 border-l-cyan-500 flex  items-center  p-4"
                >
                  <div>
                    <p>Type: {item.requirment_type}</p>
                    <p>Width: {item.width} </p>
                    <p>Height: {item.height} </p>
                    <p>Quantity: {item.quantity} </p>

                    {item?.imgFile || item?.imageUrl ? (
                      <>
                      <img src={getImageUrl(item?.imgFile || item?.imageUrl)} />
                      <button
                      type="submit"
                        onClick={() => deleteFn(item._id)}
                        className={`flex items-center justify-center btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32 md-96 ${
                          isLoading ? "bg-slate-400" : ""
                        } `}
                        disabled={isLoading}
                      >
                      {/* {isLoading ? <Loader /> : "Delete"} */}
                      Delete
                    </button>
                      </>
                    ) : (
                      <input
                        type="file"
                        id="shop_name"
                        onChange={(event) => imageHandler(event, index)}
                        // value={shop_name}
                        required
                      />
                    )}
                  </div>
                </div>
              );
            })}

            <button
              onClick={() => uploadImage()}
              className={`flex items-center justify-center btnCommon border border-r-2 text-cyan-50 px-5 py-1 text-center w-32 md-96 ${
                isLoading ? "bg-slate-400" : ""
              } `}
              disabled={isLoading}
            >
              {isLoading ? <Loader /> : "Save"}
            </button>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default InstallCreateForm;
