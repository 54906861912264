import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { signup, base_url } from "../api";

import Loader from "../components/Loader";

import Sidebar from "../components/Sidebar";
import { Box, Button, Container, Grid } from "@mui/material";

function CreateCompany() {
  const navigate = useNavigate();

  const [Company, setCompany] = useState({
    name: "",
    logoUrl: "",
  });

  const addCompanyFn = async () => {
    try {
      let response = await axios.post(`${base_url}/api/company`, Company);
      navigate("/myCompany");
    } catch (error) {}
  };

  const handlerChange = (e) => {
    setCompany({ ...Company, [e.target.name]: e.target.value });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={10}>
        <section className="h-screen max-w-4xl mx-auto">
          <div className="px-6 h-full text-gray-800">
            <div className="flex justify-center items-center flex-wrap h-full g-6">
              <div className="flex flex-col justify-center items-center space-y-5">
                <div>
                  <input
                    name="name"
                    onChange={(e) => handlerChange(e)}
                    // value={name}
                    required
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Company Name"
                  />
                </div>
                <div>
                  <input
                    name="logoUrl"
                    onChange={(e) => handlerChange(e)}
                    // value={logoUrl}
                    required
                    type="text"
                    className="form-control block w-full px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                    id="exampleFormControlInput2"
                    placeholder="Logo Link"
                  />
                </div>

                <button
                  type="submit"
                  className="btn btnCommon"
                  onClick={() => addCompanyFn()}
                >
                  Create
                  {/* {isLoading ? <Loader /> : "Create"} */}
                </button>
              </div>
            </div>
          </div>
        </section>
      </Grid>
    </Grid>
  );
}

export default CreateCompany;
